import NightWolf from '../sites/blogs/nightwolf';
import ChannelF from '../sites/radios/channelf';
import Closetnet from '../sites/blogs/closetnet';
import Cyberpunk from '../sites/radios/cyberpunk-website';
import Forever from '../sites/blogs/foreverfriend';
import Inception from '../sites/hidden/inception';
import VentSite from '../sites/hidden/ventsite';
import Website from '../sites/radios/website';

import Help1 from '../sites/help/navigating';
import Help2 from '../sites/help/listening';
import Help3 from '../sites/help/apps';
import Pinky from '../sites/blogs/pinky';
import SilverMoon from '../sites/blogs/silvermoon';
import NeonSunrise from '../sites/radios/neonsunrise';
import NeonSunset from '../sites/radios/neonsunset';
import Final from '../sites/hidden/final';
import PokemonRubyDex from '../sites/blogs/pokemonrdex';
import Help0 from '../sites/help/help';

import Home from '../sites/other/home';

import MoeMoeJP from '../sites/radios/moemoejp';
import LiquidElectrum from '../sites/radios/liquidelectrum';
import CasinoSite from '../sites/blogs/casinosite';
import CoinSite from '../sites/hidden/coinsite';
import Lol from '../sites/blogs/lol';
import SearchEngine from '../sites/other/searchengine';
import Changelog from '../sites/help/changelog';
import BlogHost from '../sites/other/bloghost';
import CortexDriver from '../sites/radios/cortexdriver';
import GoldenBreeze from '../sites/radios/goldenbreeze';
import ScreenSpaceReflection from '../sites/radios/user/screenspacereflection';
import Help5 from '../sites/help/contact';
import Zyzz from '../sites/radios/user/zyzz';
import HallowedGround from '../sites/radios/user/hallowedground';

const sites = {
    "home": Home,
    "Home": Home,
    "http://home.com/": Home,
    "http://www.home.com/": Home,

    "http://geomusic.net/": Website,
    "http://www.geomusic.net/": Website,

    "http://nightcity.net/": Cyberpunk,
    "http://www.nightcity.net/": Cyberpunk,

    "http://channelf.co/": ChannelF,
    "http://www.channelf.co/": ChannelF,
    "http://channelf.co/radio": ChannelF,
    "http://www.channelf.co/radio": ChannelF,
    "http://channelf.co/forum": ChannelF,
    "http://www.channelf.co/forum": ChannelF,

    "http://26.to/foreverfriend/": Forever,
    "http://www.26.to/foreverfriend/": Forever,

    "http://1.to/closetnet/": Closetnet,
    "http://www.1.to/closetnet": Closetnet,

    "http://nnightwolff.blog/": NightWolf,
    "http://www.nnightwolff.blog/": NightWolf,

    "http://ruuubyyy819.com/": VentSite,
    "http://www.ruuubyyy819.com/": VentSite,

    "http://inception.org/": Inception,
    "http://www.inception.org/": Inception,

    "http://forumshost.com/": BlogHost,
    "http://www.forumshost.com/": BlogHost,
    "http://forumshost.com/tos": BlogHost,
    "http://www.forumshost.com/tos": BlogHost,

    "//help/": Help0,
    "//help/navigating/": Help1,
    "//help/listening/": Help2,
    "//help/apps/": Help3,
    "//changlog": Changelog,
    "//help/contact": Help5,

    "http://pinky.blog/": Pinky,
    "http://www.pinky.blog/": Pinky,

    "http://silvermoon.site/": SilverMoon,
    "http://www.silvermoon.site/": SilverMoon,

    "http://neonsunrise.tl/": NeonSunrise,
    "http://www.neonsunrise.tl/": NeonSunrise,

    "http://neonsunset.tl/": NeonSunset,
    "http://www.neonsunset.tl/": NeonSunset,

    "http://pokemon2001.com/": PokemonRubyDex,
    "http://www.pokemon2001.com/": PokemonRubyDex,

    "http://www.com/": Final,

    "http://moemoe.jp/": MoeMoeJP,
    "http://moemoe.jp/home": MoeMoeJP,
    "http://moemoe.jp/friend": MoeMoeJP,
    "http://moemoe.jp/mail": MoeMoeJP,
    "http://moemoe.jp/about": MoeMoeJP,
    "http://www.moemoe.jp/": MoeMoeJP,
    "http://www.moemoe.jp/home": MoeMoeJP,
    "http://www.moemoe.jp/friend": MoeMoeJP,
    "http://www.moemoe.jp/mail": MoeMoeJP,
    "http://www.moemoe.jp/about": MoeMoeJP,
    
    "http://liquidelectrum.co/": LiquidElectrum,
    "http://www.liquidelectrum.co/": LiquidElectrum,
    "http://liquidelectrum.co/main": LiquidElectrum,
    "http://www.liquidelectrum.co/main": LiquidElectrum,
    "http://liquidelectrum.co/about": LiquidElectrum,
    "http://www.liquidelectrum.co/about": LiquidElectrum,

    "http://freeishslots.net/": CasinoSite,
    "http://www.freeishslots.net/": CasinoSite,

    "http://thecoinguy58.shop/": CoinSite,
    "http://www.thecoinguy58.shop/": CoinSite,
    
    "http://lol.com/": Lol,
    "http://www.lol.com/": Lol,

    "http://arina.com/": SearchEngine,
    "http://www.arina.com/": SearchEngine,
    
    "http://cortexdriver.net/": CortexDriver,
    "http://www.cortexdriver.net/": CortexDriver,

    "http://golden-breeze.live/": GoldenBreeze,
    "http://www.golden-breeze.live/": GoldenBreeze,

    "http://screen-space-reflection.blog/": ScreenSpaceReflection,
    "http://www.screen-space-reflection.blog/": ScreenSpaceReflection,

    "http://zyzz.blog/": Zyzz,
    "http://www.zyzz.blog/": Zyzz,

    "http://hallowedgrounds.blog/": HallowedGround,
    "http://www.hallowedgrounds.blog/": HallowedGround
}

export default sites;