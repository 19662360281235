const NightWolf = ({number}) => {

    return (
        <div id="http://nnightwolff.blog/" style={{width: '100%', backgroundImage: `url(/images/starbd.gif)`, boxShadow: '0 0 10px 10px black inset', padding: '10px'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img alt='decor' src='/images/nnightwolff/moonanim.gif' width={'15%'}/>
            </div>
            <hr style={{height: '1px', width: '95%'}} />
            <img alt='decor' src='/images/nnightwolff/wolfbanner2.gif' width={'100%'} />
            <div className='opener' style={{display: 'grid', gridTemplateColumns: '20% 80%'}}>
                <div style={{width: '100%', boxShadow: '0 0 5px 5px black, 0 0 5px 5px black inset', background: 'black', gridColumn: '1', gridRow: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 0 20px 0'}}>
                    <img alt='decor' src='/images/nnightwolff/wolfemail.gif' width='100%' style={{imageRendering: 'pixelated', cursor: "url(/images/cursor/pointer.cur), auto", margin: '0 auto'}}/>
                </div>
                <div className='opener' style={{textAlign: 'center', background: 'rgba(0,0,0,0.5)', boxShadow: '0 0 5px 5px black, 0 0 5px 5px black inset', gridColumn: '2', gridRow: '1'}}>
                    <h1 style={{color: 'blue', fontFamily: 'serif', textShadow: '0 0 8px white'}}>~~welcome to nnightwolf's blog~~</h1>
                    <hr style={{width: '80%', height: '5px'}}/>
                    <div style={{border: 'inset 2px', width: '80%', margin: '0 auto'}}>
                        <img alt='decor' src='/images/nnightwolff/wolfimage.gif' width={'80%'} />
                        <p style={{color: 'lightblue', textShadow: '0 0 5px white', fontFamily: 'serif', margin: '0 20px'}}>welcome to my blog! here i document everything i learn about wolves. i hope to get one someday.</p>
                    </div>
                </div>
            </div>
            <hr style={{height: '1px', width: '95%'}} />
            <div style={{height: '200px', marginTop: '50px', display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <p style={{color: 'lightblue', textShadow: '0 0 5px white', fontFamily: 'serif', margin: '10px 20px', textAlign: 'center'}}>wolf_fact_1</p>
                <p style={{color: 'lightblue', textShadow: '0 0 5px white', fontFamily: 'serif', margin: '10px 20px', textAlign: 'center'}}>wolf_fact_2</p>
                <p style={{color: 'lightblue', textShadow: '0 0 5px white', fontFamily: 'serif', margin: '10px 20px', textAlign: 'center'}}>wolf_fact_3</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <img alt='decor' src='/images/nnightwolff/wolfad.gif' style={{margin: '0 auto'}}/>
                <p style={{textAlign: 'center'}}>thanks for checking out my blog! please come back for updates. you can also contact me here: <b style={{cursor: "url(/images/cursor/pointer.cur), auto"}}>nnightwolff79@hotmail.com</b></p>
            </div>
        </div>
    )
}

export default NightWolf;