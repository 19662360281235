import { useState, useEffect, useContext } from 'react';
import { LinkContext } from '../systems/context';
import { Rnd } from "react-rnd";

import backgrounds from '../json/settings.json';

import '../css/settings.css';

const Settings = () => {

    const [state, dispatch] = useContext(LinkContext);
    const [input, setInput] = useState('');
    const [unlock, setUnlock] = useState('');
    
    const onClick = () => {
        dispatch({type: 'update_settings', settings: false})
    }

    const sendError = (name) => {
        dispatch({type: 'update_error', error: true, notice: true, errorMsg: `Sorry, you do not own the ${name} background yet!`})
    }

    const setBackground = (name) => {
        localStorage.setItem('storedBackground', `${name.split(" ")[0]}`)
        document.getElementById('desktop').style.background = `url(${name.split(" ")[0]})`;
        document.getElementById('desktop').style.backgroundSize = `${name.split(" ")[1]}`;
    }

    const unlockBg = () => {
        backgrounds.backgrounds.forEach((el) => {
            if(el.loccheck === input) {
                localStorage.setItem(input, '1')
                setUnlock(`${el.name} background unlocked!`)
            }
        })
    }

    return (
        <Rnd default={{
            x: window.innerWidth / 10,
            y: window.innerHeight / 10,
            width: 380,
            height: 240,
        }}
        minWidth="380"
        minHeight="240"
        cancel={'.content'}
        bounds={"#desktop"}
        style={{backgroundColor: "#C0C7C8", border: "groove 2px", cursor: 'url(/images/cursor/move.cur), auto', outline: 'black solid 1px', outlineOffset: '-1px', borderStyle: 'solid', borderWidth: '3px', padding: '2px', borderRightColor: 'black', borderBottomColor: 'black', borderTopColor: '#FFF8FF', borderLeftColor: '#FFF8FF'}}>
                <div className='top-bar active-toggle-bar' style={{width: '100%', height: '25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt='decor' style={{marginLeft: '5px'}} width="22px" height="22px" src='/images/My Computer.ico'/>
                        <h4 className='title active-toggle-text' style={{marginLeft: '2px'}}>My Computer</h4>
                    </div>
                    <div className='buttons content' style={{marginRight: '1px'}}>
                        <button className='top-button X' onClick={() => onClick()}><img alt='x' src='/images/winicon/X.png'/></button>
                        <button className='top-button m'><img alt='x' src='/images/winicon/Maximize.png'/></button>
                        <button className='top-button m' onClick={() => onClick()}><img alt='x' src='/images/winicon/Minimize.png'/></button>
                    </div>
                </div>

                <div className='file-bar content' style={{width: '100%', height: '25px', display: 'flex', alignItems: 'flex-start', gridRow: '2'}}>
                    <button className='files' style={{padding: '0'}}><p style={{margin: 0, color: 'black', padding: '1px 3px'}}>File</p></button>
                    <button className='edits' style={{padding: '0'}}><p style={{margin: 0, color: 'black', padding: '1px 3px'}}>Edit</p></button>
                    <button className='views' style={{padding: '0'}}><p style={{margin: 0, color: 'black', padding: '1px 3px'}}>Search</p></button>
                    <button className='helps' style={{padding: '0'}}><p style={{margin: 0, color: 'black', padding: '1px 3px'}}>Help</p></button>
                </div>
                <hr/>
                <div className='content' style={{cursor: 'url("/images/cursor/arrow.cur"), auto', overflowX: 'hidden', overflowY: 'scroll', maxHeight: 'calc(100% - 68px)', minHeight: 'calc(100% - 68px)'}}>
                    <h2 style={{textAlign: 'center', color: 'black'}}>Background Select</h2>
                    <form style={{display: 'flex'}} onSubmit={() => unlockBg()}>
                        <p style={{color: 'black', margin: 0, padding: 0}}>Background Unlock: </p>
                        <input placeholder='Code' style={{border: 'inset 3px'}} onChange={(e) => setInput(e.currentTarget.value)} type='text' />
                        <input className='submit-button' type='submit'/>
                    </form>
                    <p style={{color: 'black'}}>{unlock}</p>
                    <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)'}}>
                        {backgrounds.backgrounds.map((el) => {
                            var unlocked = false
                            localStorage.getItem(`${el.loccheck}`) !== null ? unlocked = true : unlocked = false 
                            return (
                                <div key={el.name} id={el.name} style={{cursor: 'url("/images/cursor/pointer.cur"), auto', border: 'inset 3px', background: 'white'}} className={`${el.file} ${el.size}`} onClick={(e) => unlocked ? setBackground(e.currentTarget.className) : sendError(e.currentTarget.id)}>
                                    <p style={{color: 'black'}}>{el.name}</p>
                                    <img alt={`${el.name}`} width={'50px'} height={'50px'} src={`${el.file}`} style={{filter: `grayscale(${unlocked ? 0 : 1})`}} />
                                </div>
                            )
                        })}
                    </div>
                </div>
        </Rnd>
    )
}

export default Settings;