import { useContext } from 'react';
import { LinkContext } from '../../systems/context';

const Forever = ({number}) => {

    const [state, dispatch] = useContext(LinkContext);

    const clickLink = (link) => {
        dispatch({type: 'update_link', link: link, browserInt: number})
    }

    return (
        <div id="http://26.to/foreverfriend" style={{width: '100%', backgroundImage: `url(/images/tile2.jpg)`, paddingTop: '10px'}}>
            <div className='opener' style={{textAlign: 'center', backgroundImage: 'url(/images/starbd.gif)', boxShadow: '0 0 5px 5px black, 0 0 5px 5px black inset'}}>
                <h1 style={{color: 'white', fontFamily: 'serif', textShadow: '0 0 5px white'}}>FOREVER FRIEND</h1>
                <hr style={{width: '40%', height: '1px'}}/>
                <p style={{color: '#00F9FB', textShadow: '0 0 5px #00F9FB', fontFamily: 'serif', margin: '0 20px'}}>WELCOME TO FOREVER FRIEND [`永遠の友]!!</p>
                <p style={{color: '#00F9FB', textShadow: '0 0 5px #00F9FB', fontFamily: 'serif', margin: '0 20px'}}>会って友達を作る!!</p>
                <hr style={{width: '65%', height: '1px'}}/>
                <div style={{height: '600px', marginTop: '50px'}}>
                    <p style={{color: '#00F9FB', textShadow: '0 0 5px #00F9FB', fontFamily: 'serif', margin: '0 20px'}}>私はチャットルームで働いています!!</p>
                    <img alt='decor' src="/images/construction_logo.gif" />
                    <div>
                        <form>
                            <input type={'text'} placeholder='screen name'/>
                            <input type={'textbox'} placeholder='message'/>
                            <button disabled={'true'}><p>Post</p></button>
                        </form>
                    </div>
                    <h3 style={{color: '#00F9FB', textShadow: '0 0 5px #00F9FB', fontFamily: 'serif', margin: '0 20px'}}>ログ:</h3>
                    <div className="logs" style={{padding: '15px', backgroundImage: 'url(/images/tile2.jpg)'}}>
                        <div style={{margin: '15px', background: '#151424', overflowY: 'scroll', height: '350px'}}>
                            <ul>
                                <li style={{display: 'flex', border: 'inset 1px', color: "white", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>user1105 - </i><p>闇を見たことがありますか??</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "white", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>レイ89 - </i><p>もちろん、私の友人、他にどうしてここにいることができますか</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "white", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>user1105 - </i><p>それは私を手放しません!! 怖いです!!</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "white", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>レイ89 - </i><p>私と一緒に隠れる.. <b style={{cursor: "url(/images/cursor/pointer.cur), auto"}} onClick={() => clickLink('http://1.to/closetnet/')}>http://1.to/closetnet/</b>!!</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "white", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>user1105 - </i><p>闇、ここまで届くかな??</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "white", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>レイ89 - </i><p>まだ時間はあります..</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forever;