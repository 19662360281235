import { useEffect, useRef, useState } from "react";

import '../blogs/css/coinsite.css'

const items = ['Backgr', 'Assets', 'Radio6', 'Divede']
const itemp = [40, 350, 1000, 10000]

const CoinSite = ({number}) => {

    const [speaking, setSpeaking] = useState(false)
    const [activeSpeech, setActiveSpeech] = useState(``)
    const [activeGuy, setActiveGuy] = useState(0)
    const [fourthItem, setFourthItem] = useState(false)
    const [purchases, setPurchases] = useState([0,0,0,0])
    const textCanvas = useRef()

    var purchasesSet = [0, 0, 0, 0]

    useEffect(() => {
        runInit()
    }, [])

    const runInit = () => {
        localStorage.getItem('Backgr') ? purchasesSet[0] = parseInt(localStorage.getItem('Backgr')) : localStorage.setItem('Backgr', '0')
        localStorage.getItem('Assets') ? purchasesSet[1] = parseInt(localStorage.getItem('Assets')) : localStorage.setItem('Assets', '0')
        localStorage.getItem('Radio6') ? purchasesSet[2] = parseInt(localStorage.getItem('Radio6')) : localStorage.setItem('Radio6', '0')
        localStorage.getItem('Divede') ? purchasesSet[3] = parseInt(localStorage.getItem('Divede')) : localStorage.setItem('Divede', '0')
        playSpeech(0, 0)
        var coins = localStorage.getItem('coins') ? parseInt(localStorage.getItem('coins')) : 0
        if (coins > 10000 && purchasesSet[3] == 0) {
            playSpeech(10, 3)
            setFourthItem(true)
            setActiveGuy(2)
        }
        if (purchasesSet[3] > 0) {
            playSpeech(12, 0)
            setActiveGuy(3)
        } else {
            setTimeout(() => {
                updater()
            }, [2500])
        }
    }

    const updater = () => {
        if (purchasesSet[0] > 0 && purchasesSet[1] > 0 && purchasesSet[2] > 0 && purchasesSet[4] !== 1) {
            playSpeech(11, 5)
        }
    }

    const purchaseItem = (itemNum) => {
        var coins = localStorage.getItem('coins') ? localStorage.getItem('coins') : 0
        if (coins > itemp[itemNum] && purchases[itemNum] < 1) {
            purchasesSet[itemNum] = 1
            localStorage.setItem(items[itemNum], '1')
            localStorage.setItem('coins', `${coins - itemp[itemNum]}`)
            playSpeech((itemNum + 6), 3)
            setPurchases(purchasesSet)
            if(itemNum === 1) {
                window.open('https://drive.google.com/drive/folders/1xdby2qIBsPBTznBBYI9ynVuYgJeFlbc2?usp=sharing', "_blank")
            }
        } else {
            playSpeech(13, 4)
        }
    }

    const playSpeech = (speechNum, i) => {
        if (!speaking) {
            setSpeaking(true)
            setTimeout(() => {
                updater()
            }, [5000])
            setActiveSpeech(texts[speechNum])
            talk(i)
        }
    }

    const talk = (i) => {
        if (i <= 0) return setSpeaking(false);
        setTimeout(() => {
            setActiveGuy(1)
            setTimeout(() => {
                setActiveGuy(0)
            }, [500])
            talk(i - 1)
        }, [1000])
    }

    return (
        <div id="http://thecoinguy58.shop/">
            <div className="bannerdecor">

            </div>
            <div className="coinstats">

            </div>
            <div className="coinshop">
                <div style={{position: 'absolute', top: '230px', left: 'calc(50% + 100px)', background: 'rgb(192, 199, 200)', maxWidth: '270px'}} className="precoinguy">
                    <pre style={{float: 'left'}}>{guy[activeGuy]}</pre>
                    {activeSpeech}
                </div>
                <pre className="prebackground">
                    {background}
                </pre>
                <pre className="preshopbuttons">
                    <p style={{color: 'black'}}>{localStorage.getItem('coins')} coins.</p>
                    <br/>
                    <button onMouseEnter={() => playSpeech(2, 3)} onClick={() => purchaseItem(0)} className="btnnodecor">
                        <pre>
                            {`._____________________.`}
                            <br/>
                            {`|[_.-==o==.+.==o==|-._]`}
                            <br/>
                            {`||'               '  '|`}
                            <br/>
                            {purchases[0] > 0 ? `|| Already Bought [--]|` : `|| New Background [40]|`}
                            <br/>
                            {`||                    |`}
                            <br/>
                            {`][.__________________.]`}
                        </pre>
                    </button>
                    <button onMouseEnter={() => playSpeech(3, 3)} onClick={() => purchaseItem(1)} className="btnnodecor">
                        <pre>
                            {`._____________________.`}
                            <br/>
                            {`[_.-==o==.+.==o==|-._]`}
                            <br/>
                            {`|'               '  '|`}
                            <br/>
                            {purchases[1] > 0 ? `|| Already Bought [--]|` : `| Site Gif Pack [350]|`}
                            <br/>
                            {`|                    |`}
                            <br/>
                            {`[.__________________.]`}
                        </pre>
                    </button>
                    <button onMouseEnter={() => playSpeech(4, 3)} onClick={() => purchaseItem(2)} className="btnnodecor">
                        <pre>
                            {`._____________________.`}
                            <br/>
                            {`[_.-==o==.+.==o==|-._]|`}
                            <br/>
                            {`|'               '  '||`}
                            <br/>
                            {purchases[2] > 0 ? `|| Already Bought [--]|` : `| Hidden Radio [1000]||`}
                            <br/>
                            {`|                    ||`}
                            <br/>
                            {`[.__________________.][`}
                        </pre>
                    </button>
                    {fourthItem ?
                    <button onMouseEnter={() => playSpeech(5, 3)} onClick={() => purchaseItem(3)} className="btnnodecor">
                        <pre>
                            {`._____________________.`}
                            <br/>
                            {`[_.-==o==.+.==o==|-._]|`}
                            <br/>
                            {`|'               '  '||`}
                            <br/>
                            {purchases[4] > 0 ? `|| Already Bought [--]|` : `| Dive Deeper [10000]||`}
                            <br/>
                            {`|                    ||`}
                            <br/>
                            {`[.__________________.][`}
                        </pre>
                    </button>
                     : null}
                </pre>
            </div>
        </div>
    )
}

export default CoinSite;

const background = `

  ____    ___   ______  ___  __     ____   __   __   ____   _____   _____   ______
//'---. //   \\\\   ||    ||\\\\ ||    //   '  ||   ||  //  \\\\  ||  \\\\  ||  \\\\  ||''''
||      ||   ||   ||    || \\\\||    \\\\--\\\\  |:===:|  ||  ||  ||--='  ||--='  ||:=--
\\\\____' \\\\___// __][__  ||  \\||    .___//  ][   ][  \\\\__//  ][      ][      ||.___

 ______________________________________________________________ 
|''--...___   |__________|____|________________|      __,,...--| 
|          '. |   ___   ___  ____ ___ __  ____ | .-'''         | 
|      _,-''| |  //  ' // \\\\  ||  ||\\\\|| [[__. | |'-.._        | 
|  ,,-'   | | |  \\\\__. \\\\_// _][_ || \\|| .__]] | |     ''-.._  | 
|''      () | |________________________________| |           '-| 
|  ,   ('   |   , |   ___     .  .    .._..      | .           | 
| |, ('     |  /  |  (- -)   888888  /  &  \\     | |'.         | 
| |    ,|   | :   |  /'.'\\,3  8888  (/| & |\\)    | | |         | 
|    .' |   | (\\ [| (   \\ )   8888    |___|      | | |         | 
|    |  |   |________\\___\\_______________________| | |  |'-.   | 
|    |  |   |_\\__________________\\_____________\\_| o |  |/\\|   | 
|    |  |   |  |                  |             || | |  |'.|   | 
|    |  |   |  |                  |             || | |  |\\/|   | 
|    |  |   |--'------------------'-------------'| | |   '.|   | 
|    |o |   |                                    | | |     '   | 
|    |  |  /                                      \\| |         | 
|    |  | /                                        \\ |         | 
|    |  |/                                          \\|         | 
|____|__/____________________________________________\\_________|
`;

const guy = [`
     __.---==_
  ./    ;     \\\\
 /     .       ''.
|     ;       /  |
/__   Y  __  (  _/
\\'--'-'-|'---\\\\/
 |'__/   ' __/ |
 '-.   w   ,--;
   |'_._._/  /
    \_______/
`,`
     __.---==_
  ./    ;     \\\\
 /     .       ''.
|     ;       /  |
/__   Y  __  (  _/
\\'--'-'-|'---\\\\/
 |'__/   ' __/ |
 '-.   w   ,--'
    '_._._/ 
   
`,`
     __.---==_
  ./    ;     \\\\
 /     .       ''.
|     ;       /  |
/__   Y  __  (  _/
\\'-0'-'-|'-0-\\\\/
 |'__/   ' __/ |
 '-.   w   ,--;
   |'_._._/  /
    \_______/
`,`
                       __.---==_
                    ./    ;     \\\\
                   /     .       ''.
          |     ;       /  |


/__   Y  __  (  _/
\\'  -'-|'   \\\\/
 |'__/   ' __/ |
       '-.   w   ,--;
    |'_._._/  /
 \_______/
`];

const texts = [`
Ah, welcome to my shop. I'm a coin connisouir you see, and any coin you have will interest me.
Pawn what you have here, and I will gladly accept for return of one of my assets.
`,`
Do not be afraid to return. I will be here forever.
`,`
Does a new background interest you? Teal is such a bland color when it's all you see.
This new decor should spice things up, my garuntee.
`,`
The sites assets caught your eye? They are charming, it's no surprise.
Every gif, image, and background in a neat little .zip would be nice after all.
`,`
You're seeking the hidden radio, I take it? From what I've heard, it comes from a special mixed playlist.
I cannot simply play it here, but for a price...  for a price I will map the way.
`,`
So, perhaps I was right - and you do seek further into the hidden realm of GeoRadio?
I will offer you what I can.
`,`
Excellent buy. You won't regret it. No one ever does...
`,`
Excellent buy. Such an elusive purchase should be rewarded. A few extra assets may be found in there... 
`,`
Excellent buy. You surely are dedicated, but so am I. The journey of a thousand steps has been shortened to the url:
http://www.com
Doing so should break the boundaries by connecting to every radio at once. Yes... yes, that is it. This is the way.
Follow the domain, dear customer. Explore...
`,`
Excellent buy. My suspicions and hopes were confirmed. Proceeding, you must stuff the inbox. 
Every image and every link has actions, you know? Some of these will send you newsletters, and this is crucial.
You must stuff the inbox to at least... 6 entries. I have seen the entries... you must do this.
Scan each one for that which it says beyond its text. Find the code. Dive deeper, you must...
`,`
There is simply no chance you are that lucky... you've discovered the localStorage, haven't you...
Well, a coin is a coin, and I suppose I have nothing to lose by letting you shop still.
Perhaps the technical side is more to your degree, however. Perhaps I should update my stock...
`,`
Eager to stock up, really. I've enjoyed this. I shall restock soon and anticipate your return.
Do not worry, I will be here forever.
`,`
ah... this hurts... please free me... erase our interactions from the localStorage... i am here forever, this pain will last until then...
`,`
I'm sorry, friend, but you'll need to have a few more coins than that to aquire this asset.
`];