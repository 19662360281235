import { useContext, useEffect, useState } from 'react';
import { LinkContext } from '../../systems/context';

import './css/casinosite.css';

const slotImages = ['lemon', 'peach', 'cherry', 'watermelon', 'strawberry']
const slotWinner = [5, 50, 100, 250, 5000]

const CasinoSite = ({number}) => {
    
    const [state, dispatch] = useContext(LinkContext);
    const [winner, setWinner] = useState(false);
    const [wintext, setWintext] = useState('');
    const [coinst, setCoinst] = useState(0);

    const clickLink = (link) => {
        dispatch({type: 'update_link', link: link, browserInt: number})
    }

    useEffect(() => {
        localStorage.getItem('coins') !== null ? setCoinst(parseInt(localStorage.getItem('coins'))) : setCoinst(0)
    }, [])

    var beingAwesome = false;

    const gamble = () => {
        if (!beingAwesome) {
            beingAwesome = !beingAwesome
            if (localStorage.getItem('isAwesome = true') != '1') {
                localStorage.setItem('isAwesome = true', '1')
                dispatch({type: 'update_error', error: true, notice: true, errorMsg: '"freeishslots.net" has downloaded a background to your computer. "Leafmat" background unlocked!'})
            }
            var slot1 = Math.round(Math.random() * 4)
            var slot2 = Math.round(Math.random() > 0.85 ? slot1 : Math.random() * 4)
            var slot3 = Math.round(Math.random() > 0.85 ? slot2 : Math.random() * 4)
            document.getElementById('slot1img').src = `/images/freeishslots/rolling.webp`
            document.getElementById('slot2img').src = `/images/freeishslots/rolling.webp`
            document.getElementById('slot3img').src = `/images/freeishslots/rolling.webp`
            setTimeout(() => {
                document.getElementById('slot1img').src = `/images/freeishslots/${slotImages[slot1]}.gif`
            }, [1000])
            setTimeout(() => {
                document.getElementById('slot2img').src = `/images/freeishslots/${slotImages[slot2]}.gif`
            }, [1500])
            setTimeout(() => {
                document.getElementById('slot3img').src = `/images/freeishslots/${slotImages[slot3]}.gif`
                if (slot1 === slot2 && slot2 === slot3) {
                    var coins = localStorage.getItem('coins') ? parseInt(localStorage.getItem('coins')) : 0 
                    var winamt = slotWinner[slot1]
                    localStorage.setItem('coins', (coins + winamt))
                    setCoinst(coins + winamt)
                    setWinner(`/images/freeishslots/win/${slotImages[slot1]}.gif`)
                    setWintext(`${slotImages[slot1]}!! You've won ${winamt} coins.`)
                }
                beingAwesome = !beingAwesome
            }, [2500])
        }
    }

    return (
        <div id="http://freeishslots.net/" style={{background: 'green', backgroundSize: '400px', imageRendering: 'pixelated'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <h1 style={{padding: '10px', whiteSpace: 'nowrap', color: 'lightblue', backgroundColor: 'green'}}>FREE SLOTS .NET</h1>
        </div>
            <div style={{padding: '10vh 0', display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
            <img alt='decor' src='/images/freeishslots/treesep4.gif' width={'100%'}/>
                <div style={{width: '100%', display: 'grid', gridTemplateColumns: '33% 34% 33%', background: 'url(/images/freeishslots/leaftile.png)', backgroundSize: '50px',}}>
                    <div className="rulesheet" style={{background: 'rgba(0,0,0,0.3)', display: 'flex', flexDirection: 'column', padding: '3px'}}>
                        <h1>Rules</h1>
                        <p>1. First of all, <b>have fun!!</b></p>
                        <p>2. Don't be afraid to be yourself!!</p>
                        <p>3. Gambling is awesome, make sure to tell a friend!!</p>
                        <p>4. No refunds are issued, if you need money-back, keep pushing until you turn a profit. Statistically it <i>will</i> happen!!</p>
                    </div>
                    <div className="slotmachine" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3px'}}>
                        <h1>Slots</h1>
                        <div className="machine-outer">
                            <div className="machine-inner">
                                <div className="rotaters" style={{display: 'grid', gridTemplateColumns: 'repeat(3, 33%)', padding: '10px', background: 'black'}}>
                                    <div className="slot1">
                                        <img id="slot1img" alt='slot 1' width={'20px'} height={'20px'} src={`/images/freeishslots/strawberry.gif`} />
                                    </div>
                                    <div className="slot2">
                                        <img id="slot2img" alt='slot 2' width={'20px'} height={'20px'} src={`/images/freeishslots/strawberry.gif`} />
                                    </div>
                                    <div className="slot3">
                                        <img id="slot3img" alt='slot 3' width={'20px'} height={'20px'} src={`/images/freeishslots/strawberry.gif`} />
                                    </div>
                                </div>
                                <img className='play-button-casino' src='/images/freeishslots/play.gif' width={'80px'} height={'20px'} onClick={() => gamble()} alt='play' />
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <p>{wintext}</p>
                                <img alt='' src={`${winner}`} />
                            </div>
                            <p>Total coins: {coinst}</p>
                            <div style={{display: 'flex'}}>
                                <img src='/images/freeishslots/lemon.gif' alt='decor' width={'25px'} height={'25px'} /><p>x3 = <b>$5</b></p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <img src='/images/freeishslots/peach.gif' alt='decor' width={'25px'} height={'25px'} /><p>x3 = <b>$50</b></p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <img src='/images/freeishslots/cherry.gif' alt='decor' width={'25px'} height={'25px'} /><p>x3 = <b>$100</b></p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <img src='/images/freeishslots/watermelon.gif' alt='decor' width={'25px'} height={'25px'} /><p>x3 = <b>$250</b></p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <img src='/images/freeishslots/strawberry.gif' alt='decor' width={'25px'} height={'25px'} /><p>x3 = <b>$5000</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="space2" style={{background: 'rgba(0,0,0,0.3)', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '3px'}}>
                        <h1>Links</h1>
                        <a href={`#scrollto-${number}`} style={{width: '80%', height: '43px', cursor: "url(/images/cursor/pointer.cur), auto"}}><img alt='clickable ad decor' title='http://www.thecoinguy58.shop/' onClick={() => clickLink('http://thecoinguy58.shop/')} src='/images/freeishslots/coinflip.gif' width='100%' height='43px' style={{imageRendering: 'pixelated'}}/></a>
                        <a href={`#scrollto-${number}`} style={{width: '80%', height: '43px', cursor: "url(/images/cursor/pointer.cur), auto"}}><img alt='clickable ad decor' title='http://www.liquidelectrum.co/main' onClick={() => clickLink('http://liquidelectrum.co/main')} src='/images/liquidelectrum/eorgbutton.gif' width='100%' height='43px' style={{imageRendering: 'pixelated'}}/></a>
                    </div>
                </div>
                <div style={{padding: '10px 15%', background: 'black'}}>
                    <p>DISCLAIMER: Gambling is fun and awesome and there is little to no associated risk because you <i>will</i> eventually win, meaning your
                    losses will be regained quickly! 99% of gamblers quit before their big win. Never give up!</p>
                    <p style={{fontSize: '8px'}}>DISCLAIMER DISCLAIMER: Gambling is highly addictive and not actually fun and/or awesome, but rather a mental stunt
                    of chance, similar to extreme risk scenarios or near-death experiences. There is an extreme associated risk with gambing, 
                    as less than a tenth of a percent of gamblers make a positive income at any point in their life. Gambling
                    is extremely addictive and should not be exposed to anyone of a young age, especially those with an addicitve personality.
                    Coins may or may not equate to the real life dollar and may or may not be directly editable in the localStorage.</p>
                </div>
            </div>
        </div>
    )
}

export default CasinoSite