const VentSite = () => {

    return (
        <div id="http://ruuubyyy819.com/" style={{width: '100%', backgroundImage: `url(/images/tile2.jpg)`, paddingTop: '10px'}}>
            <div className='opener' style={{textAlign: 'left', backgroundImage: 'url(/images/starbd.gif)', boxShadow: '0 0 5px 5px black, 0 0 5px 5px black inset'}}>
                <h1>This once was something very interesting, but now it's gone. Maybe it'll come back one day. Regardless, good thinking finding this site! Background code: angels</h1>
            </div>
        </div>
    )
}

export default VentSite;