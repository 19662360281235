import dex from '../../json/pokedex.json';

const PokemonRubyDex = ({number}) => {

    return (
        <div id="http://pokemon2001.com/" style={{width: '100%', display: 'flex', alignItems: 'center', gap: '30px', padding: '30px 0', flexDirection: 'column', backgroundImage: `url(/images/pokedex/fondo.jpg)`, boxShadow: '0 0 10px 10px black inset'}}>
            <div className='pokedex-welcome'>
                <h2>--POKEMON RUBY DEX ONLINE--</h2>
            </div>

            <div className='pokedex' style={{backgroundImage: 'url(/images/pokedex/fondo_3.jpg', padding: '10px', display: 'grid', gridTemplateColumns: '50% 50%', gap: '5px'}}>
                <div style={{display: 'flex', flexDirection: 'column', padding: '5px'}}>
                    <img alt='decor' src='/images/pokedex/1_25.gif'/>
                    <img alt='decor' src='/images/pokedex/26_50.gif'/>
                    <img alt='decor' src='/images/pokedex/51_63.gif'/>
                    <img alt='decor' src='/images/pokedex/64_88.gif'/>
                    <img alt='decor' src='/images/pokedex/89_113.gif'/>
                    <img alt='decor' src='/images/pokedex/114_126.gif'/>
                    <img alt='decor' src='/images/pokedex/127_151.gif'/>
                    <img alt='decor' src='/images/pokedex/152_176.gif'/>
                    <img alt='decor' src='/images/pokedex/177a.gif'/>
                    <img alt='decor' src='/images/pokedex/178_190.gif'/>
                    <img alt='decor' src='/images/pokedex/190a.gif'/>
                    <img alt='decor' src='/images/pokedex/191_215.gif'/>
                    <img alt='decor' src='/images/pokedex/216_240.gif'/>
                    <img alt='decor' src='/images/pokedex/241_251.gif'/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', padding: '5px'}}>
                    {dex.dex.map((el) => {
                        return (
                            <div style={{marginTop: '1px', border: 'solid 1px white'}} key={el.id}>
                                <p>{el.name.english} ({el.name.japanese}) - {el.id}</p>
                                <p>{el.type}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default PokemonRubyDex;