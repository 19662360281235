import { useContext, useEffect, useState } from 'react';
import { LinkContext } from '../../systems/context';

import MusicPlayer from '../../musicplayer';

const Final = ({number}) => {

    const [state, dispatch] = useContext(LinkContext);
    const [radioload, setRadioload] = useState(false)

    const changeStation = (num) => {
        dispatch({type: 'update_radio', radio: num})
        setRadioload(true)
    }

    useEffect(() => {
        changeStation(7)
    }, [])

    return (
        <div id="http://www.com/" style={{width: '100%', backgroundImage: `url(/images/sunrise/Sunsettrees.gif)`, backgroundSize: 'contain', imageRendering: 'pixelated', paddingTop: '10px'}}>
            <div style={{height: '100%', marginTop: '10px'}}>
                {radioload ?
                    <MusicPlayer radionum={7} number={number} themeID={7} />
                : null}
            </div>
        </div>
    )
}

export default Final;