import { useContext } from 'react';
import { LinkContext } from '../../systems/context';

import './css/pinky.css';

const Pinky = ({number}) => {

    const [state, dispatch] = useContext(LinkContext);

    const clickLink = (link) => {
        dispatch({type: 'update_link', link: link, browserInt: number})
    }

    const unlockBg = () => {
        localStorage.setItem('Backgr2', '1')
        dispatch({type: 'update_error', error: true, notice: true, errorMsg: 'Background pack downloaded! Strawmat and Wave backgrounds unlocked!'})
    }

    return (
        <div id="http://pinky.blog/" className='pinky' style={{width: '100%', display: 'flex', alignItems: 'center', gap: '30px', padding: '30px 0', flexDirection: 'column', backgroundImage: `url(/images/pinky/mdbg.gif)`, boxShadow: '0 0 10px 10px pink inset'}}>
            <div className='pinkydiv'>
                <img alt='decor' src='/images/nightcity/sb2.gif' width={'15%'}/>
                <p>Welcome!!!!</p>
                <textarea style={{color: 'pink', font: 'MS UI Gothic', fontSize: '8pt'}} value={`<a href='http://www.pinky.blog/' target='_blank'><img alt='decor' src='http://www.pinky.blog/sb2.gif'/></a>`}></textarea>
            </div>

            <div className='pinkydiv'>
                <p>Download my free background pack!</p>
                <p>
                    Download here!! <a style={{textDecoration: 'underline', color: 'blue', cursor: 'url(/images/cursor/pointer.cur), auto'}} onClick={() => unlockBg()}>Download</a>
                </p>
            </div>


            <div className='pinkydiv'>
                <p>3/15 - UPDATE</p>
                <p>
                    Sorry for the delay in blog posts. I've been very busy recently working on banners and icons, and LIFE. 
                    I'm going to keep everything going for as long as I can here, so if I dissapear randomly someday, PLEASE SEND SOMEONE!! HAHA
                    I uploaded some in the last post I made. You should totally check them out!!
                    And again, if you would like one, please use my contact form on the bottom of the page.
                </p>
                <p>NEW STUFF IVE MADE FOR PEOPLE:</p>
                <div style={{display: 'flex'}}>
                    <img alt='decor' src='/images/pinky/heaven-new.gif'/>
                    <img alt='decor' src='/images/pinky/3232chibimoon.gif'/>
                    <img alt='decor' src='/images/pinky/pixelwebring.gif'/>
                    <img alt='decor' src='/images/pinky/eclipse.gif'/>
                </div>
            </div>

            <div className='pinkydiv'>
                <p>12/25 - MERRY CHRISTMAS!!</p>
                <p>
                    I've made some pokemon assets recently for fun while working on other projects and school!!
                    They're FREE TO USE for CHRISTMAS!! ENJOY!!
                    These are some of my favorite pokemon too.
                </p>
                <p>ENJOY:</p>
                <div style={{display: 'flex'}}>
                    <img alt='decor' src='/images/kantoonline/clefairy.gif'/>
                    <img alt='decor' src='/images/kantoonline/clefable.gif'/>
                    <img alt='decor' src='/images/kantoonline/pikachu.gif'/>
                    <img alt='decor' src='/images/kantoonline/raichu.gif'/>
                    <img alt='decor' src='/images/kantoonline/chansey.gif'/>
                </div>
            </div>

            <div className='pinkydiv'>
                <p>----FORM SUBMISSIONS LOST</p>
                <p>
                    Earlier form submissions from this site have been lost, most likely due to a domain transfer. Please contact the site administrator for more information to resolve this issue.
                </p>
                <p onClick={() => clickLink('http://www.formshost.com/')}>--http://www.formshost.com/</p>
            </div>

            <div className='pinkydiv'>
                <p>LINKS:</p>
                <div style={{display: 'grid', gridTemplateColumns: '50% 50%'}}>
                    <img title='blank' alt='decor' style={{cursor: 'url(/images/cursor/pointer.cur), auto'}} onClick={() => clickLink('blank')} src='/images/nightcity/a_banner01.gif'/>
                    <img title='http://pokemon2001.com/' alt='decor' src='/images/sunrise/pokemonad.gif' width='80%' height='43px' onClick={() => clickLink('http://pokemon2001.com/')} style={{imageRendering: 'pixelated', cursor: "url(/images/cursor/pointer.cur), auto"}}/>
                    <img title='http://www.silvermoon.site/' alt='decor' style={{cursor: 'url(/images/cursor/pointer.cur), auto'}} onClick={() => clickLink('http://www.silvermoon.site/')} src='/images/nightcity/moonbutton_1.gif'/>
                    <img title='http://www.moemoe.jp/home' alt='decor' style={{cursor: 'url(/images/cursor/pointer.cur), auto'}} onClick={() => clickLink('http://moemoe.jp/home')} src='/images/moemoejp/banner_princess02.gif'/>
                </div>
            </div>
        </div>
    )
}

export default Pinky;