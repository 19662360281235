import { useContext } from 'react';
import { LinkContext } from '../../systems/context';

const Lol = ({number}) => {

    const [state, dispatch] = useContext(LinkContext);

    var mailnums = [6, 9, 10, 11]

    const mailUpdate = () => {
        var updated = state.email
        var rand = Math.round(Math.random() * 3)
        updated.push(mailnums[rand]) //whatever, cant be bothered to actually make each mail piece exclusive
        dispatch({type: 'update_email', email: updated})
    }

    return (
        <div id='http://lol.com/' style={{background: 'black', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <h1>Welcome to LOL.com!!</h1>
            <p>We are currently working on getting our articles up, but the mailing list is up already!! Try it out:</p>
            <button style={{marginTop: '50px'}} onClick={() => mailUpdate()}><h2 style={{color: 'black'}}>Click here to recieve funny mail!! :)</h2></button>
        </div>
    )
}

export default Lol;