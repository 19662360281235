import { useContext } from 'react';
import { LinkContext } from '../../systems/context';

const Closetnet = ({number}) => {

    const [state, dispatch] = useContext(LinkContext);

    const clickLink = (link) => {
        dispatch({type: 'update_link', link: link, browserInt: number})
    }

    return (
        <div id="http://1.to/closetnet" style={{width: '100%', backgroundImage: `url(/images/cloudbg.gif)`, paddingTop: '70px'}}>
            <div className='opener' style={{textAlign: 'center', backgroundImage: 'url(/images/snowflake.gif)', boxShadow: '0 0 15px 15px lightblue, 0 0 15px 15px lightblue inset'}}>
                <div style={{border: 'inset 3px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', margin: '0 auto', background: 'teal'}}>
                    <h1 style={{color: 'white', fontFamily: 'serif', textShadow: '0 0 5px lightblue'}}>closet.net</h1>
                    <p style={{color: 'white', fontFamily: 'serif', margin: '0 20px'}}>closet.net forums are currently: <i style={{color: 'lightgreen', textShadow: '0 0 5px lightgreen'}}>Open</i></p>
                    <p style={{color: 'white', fontFamily: 'serif', margin: '0 20px'}}>-- 楽しむ!! --</p>
                </div>
                <hr style={{width: '65%', height: '10px'}}/>
                <div style={{height: '600px', marginTop: '50px'}}>
                    <div>
                        <form>
                            <input type={'text'} placeholder='screen name'/>
                            <input type={'textbox'} placeholder='message'/>
                            <button disabled={'true'}><p>Post</p></button>
                        </form>
                    </div>
                    <h3 style={{color: 'black', textShadow: '0 0 5px black', fontFamily: 'serif', margin: '0 20px'}}>ログ:</h3>
                    <div className="logs" style={{boxShadow: '0 0 15px 15px lightblue, 0 0 15px 15px lightblue inset'}}>
                        <div style={{margin: '15px', backgroundImage: 'url(/images/snowflake2.gif)', overflowY: 'scroll', height: '350px'}}>
                            <ul style={{background: 'rgba(0,0,0,0.1)'}}>
                                <li style={{display: 'flex', border: 'inset 1px', color: "black", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>user1105 - </i><p style={{color: 'black'}}>こんにちは?</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "black", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>レイ89 - </i><p style={{color: 'black'}}>いらっしゃいませ。もう治りましたか?</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "black", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>user1105 - </i><p style={{color: 'black'}}>よくわかりません。闇はここまで来るのか??</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "black", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>レイ89 - </i><p style={{color: 'black'}}>www.inception.org</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "black", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>user1105 - </i><p style={{color: 'black'}}>何??</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "black", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>レイ89 - </i><p style={{color: 'black'}}>それは私たちに届きます。だから私たちは走ります</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "black", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>レイ89 - </i><p style={{color: 'black'}}>新しいサイトを作成し、その方法を説明します。フォローしてください+</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "black", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>user1105 - </i><p style={{color: 'black'}}>そこにいる？私はあなたに従います??</p></li>
                                <li style={{display: 'flex', border: 'inset 1px', color: "black", alignItems: 'center', gap: '20px'}}><i style={{opacity: '0.7'}}>レイ89 - </i><p style={{color: 'black'}}>それに取り組んでいます。ちょっと待って。</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p style={{color: 'black', textShadow: '0 0 5px black', fontFamily: 'serif', margin: '0 20px'}}><i><b>closet.net</b> and all services hosted by <b style={{cursor: "url(/images/cursor/pointer.cur), auto"}} onClick={() => clickLink('http://www.forumshost.com/')}>http://www.forumshost.com/</b></i></p>
            </div>
        </div>
    )
}

export default Closetnet;